import { defineNuxtPlugin } from '#imports';
import configPaths from '@@/configuration/paths.json';

const paths = {
    landingPage: {
        'fi-FI': '/',
        'en-EU': '/en-EU',
        'sv-SE': '/sv-SE',
    },
    checkout: {
        'fi-FI': '/kassa',
        'en-EU': '/checkout',
        'sv-SE': '/kassa',
    },
    stationLocator: {
        'fi-FI': '/autopesulat',
    },
    washingInstructions: {
        'fi-FI': '/autonpesuohjeet/pesuohje',
    },
    buyersGuide: {
        'fi-FI': '/autonpesuohjeet/osto-opas',
    },
    terms: {
        'fi-FI': '/kayttoehdot',
    },
    ...configPaths,
};

const endpoints = {
    layout: 'cms-view/v1/content/layout',
    page: 'cms-view/v1/content/page',
    order: 'digital-order/v1/orders/initialize',
    complete: 'digital-order/v1/orders/complete',
    customer: 'b2c-my-pages-view-v2/v1/customer/details',
    cards: 'b2c-my-pages-view-v2/v1/customer/card/emv/list',
};

export { paths };
export { endpoints };

export default defineNuxtPlugin(nuxtApp => {
    const { $i18n } = nuxtApp;

    return {
        provide: {
            paths: {
                getPath(key) {
                    try {
                        if (paths[key][$i18n.locale.value]) {
                            return paths[key][$i18n.locale.value];
                        }
                    } catch (e) {
                        if (process.env.NODE_ENV === 'development') {
                            console.warn(`No path found in paths plugin for ${key}`);
                        }

                        return '/';
                    }
                },
                getEndpoint(alias) {
                    if (endpoints[alias]) {
                        return endpoints[alias];
                    }

                    return '';
                },
            },
        },
    };
});
