import { default as _91_46_46_46url_93p2UUXkYpgdMeta } from "/service/src/pages/[...url].vue?macro=true";
import { default as checkoutKmOiuCfOdbMeta } from "/service/src/pages/checkout.vue?macro=true";
import { default as component_45stubzFxfuLkpmAMeta } from "/service/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubzFxfuLkpmA } from "/service/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "url___fi-FI",
    path: "/:url(.*)*",
    component: () => import("/service/src/pages/[...url].vue")
  },
  {
    name: "checkout___fi-FI",
    path: "/kassa",
    component: () => import("/service/src/pages/checkout.vue")
  },
  {
    name: component_45stubzFxfuLkpmAMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubzFxfuLkpmA
  },
  {
    name: component_45stubzFxfuLkpmAMeta?.name,
    path: "/fi-FI-sitemap.xml",
    component: component_45stubzFxfuLkpmA
  }
]