import validate from "/service/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/service/src/middleware/01.auth.global.js";
import _02_45redirect_45global from "/service/src/middleware/02.redirect.global.js";
import _03_45load_45global from "/service/src/middleware/03.load.global.js";
import _04_45redirect_45module_45global from "/service/src/middleware/04.redirect.module.global.js";
import _05_45checkout_45global from "/service/src/middleware/05.checkout.global.js";
import manifest_45route_45rule from "/service/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45redirect_45global,
  _03_45load_45global,
  _04_45redirect_45module_45global,
  _05_45checkout_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}