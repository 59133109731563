import { defineNuxtPlugin } from '#imports';
import httpStatus from 'http-status';

export default defineNuxtPlugin(() => ({
    provide: {
        statusMessages: {
            getMessage(status) {
                switch (status) {
                    case httpStatus.INTERNAL_SERVER_ERROR:
                    return 'Internal server error';

                    default:
                    case httpStatus.NOT_FOUND:
                    return 'Page not found';
                }
            },
        },
    },
}));
