import { defineNuxtRouteMiddleware, useNuxtApp } from '#imports';
import { useCheckoutStore } from '~dsc/stores/checkout';

export default defineNuxtRouteMiddleware(route => {
    const { $i18n } = useNuxtApp();
    const checkoutStore = useCheckoutStore();

    if (route.name === `checkout___${$i18n.locale.value}`) {
        if (route.query?.step && route.query?.transactionId && route.query?.responseCode === 'OK') {
            checkoutStore.$patch({ step: 4 });
        } else if (route.query?.step && route.query?.step < 4) {
            checkoutStore.$patch({ step: Number(route.query.step) });
        } else {
            checkoutStore.$patch({ step: 1 });
        }
    }
});
