import { usePageStore } from '~dsc/stores/cms/page';
import { find } from 'lodash-es/collection';
import { defineNuxtRouteMiddleware, navigateTo, storeToRefs } from '#imports';
import { httpStatus } from '~dsc/utils/http-status';

export default defineNuxtRouteMiddleware(() => {
    const { pageBuffer, pageData } = storeToRefs(usePageStore());
    const currentData = import.meta.client ? pageBuffer : pageData;

    if (currentData.value) {
        const redirect = find(currentData.value, (part => part.contentType === 'cardList' && part.display.component === 'redirect'));
        if (redirect) {
            return navigateTo(
                redirect.display.url,
                {
                    external: redirect.display.url.match(/^http/) !== null,
                    redirectCode: httpStatus.TEMPORARY_REDIRECT,
                },
            );
        }
    }
});
