import { useUserStore } from '~dsc/stores/cms/user';
import { useNuxtApp, defineNuxtRouteMiddleware, useRequestHeaders } from '#imports';

let headers;

export default defineNuxtRouteMiddleware(() => new Promise(resolve => {
    const { $i18n } = useNuxtApp();
    let userStatus;

    if (import.meta.server) {
        headers = useRequestHeaders(['cookie']);

        userStatus = $fetch.raw('/api/v1/auth', {
            headers: {
                'x-st1-locale': $i18n.locale.value,
                cookie: headers.cookie,
            },
        });
    } else {
        userStatus = $fetch('/api/v1/auth', {
            headers: {
                'x-st1-locale': $i18n.locale.value,
            },
        });
    }
    resolve(userStatus);
}).then(userStatus => new Promise(resolve => {
    const authorized = import.meta.server ? userStatus._data : userStatus; // eslint-disable-line no-underscore-dangle
    let userData;

    if (authorized === 'AUTHORIZED') {
        if (import.meta.server) {
            const updatedCookies = userStatus.headers.getSetCookie();
            let cookies;

            if (updatedCookies.length > 0) {
                cookies = updatedCookies.map(cookie => cookie.split(';')[0]).join('; ');
            } else {
                cookies = headers.cookie;
            }

            userData = $fetch('/proxy/b2c-my-pages-view-v2/v1/customer/details', {
                headers: {
                    cookie: cookies,
                },
            });
        } else {
            userData = $fetch('/proxy/b2c-my-pages-view-v2/v1/customer/details');
        }
        resolve(userData);
    } else {
        resolve(false);
    }
}).then(res => {
    if (res) {
        useUserStore().$patch({
            user: {
                firstname: res.firstName,
                lastname: res.lastName,
                email: res.emailToken,
                phone: res.phoneNumber,
                telephonePrefix: useUserStore().parsePhone('prefix', res.phoneNumber),
                telephoneNumber: useUserStore().parsePhone('number', res.phoneNumber),
            },
            loggedIn: true,
        });
    } else {
        useUserStore().resetUser();
    }
})));
