<template>
    <div class="page">
        <main class="page-container">
            <LayoutCmsHeader
                :navigation="layoutData?.navigation"
                :links="additionalLinks"
                theme="white"
                minicart
                :checkout-to="$paths.getPath('checkout')"
            />
            <DSErrorPage
                v-bind="parsedError"
            />
        </main>
        <LayoutFooter
            :icons="layoutData?.icons"
            :items="layoutData?.footerLinks"
            :download="layoutData?.downloadLinks"
            :some="layoutData?.someLinks"
            :extras="layoutData?.extraLinks"
        />
    </div>
</template>
<script setup>
import { computed, useHead, useError, useNuxtApp, useI18n, onUnmounted } from '#imports';
import { storeToRefs } from 'pinia';
import { useLayoutStore } from '~dsc/stores/cms/layout';
import { usePageStore } from '~dsc/stores/cms/page';

const { $i18n } = useNuxtApp();
const { layoutData } = await storeToRefs(useLayoutStore());
await useLayoutStore().layoutLoad();
const { value: error } = useError();

useHead({
    htmlAttrs: {
        class: 'error',
    },
});

onUnmounted(() => {
    usePageStore().present();
});

const additionalLinks = computed(() => ([
    { href: '/autopesulat', icon: 'map', title: $i18n.t('additionalLinks.carwash'), position: 'left' },
    { href: 'https://kirjaudu.st1.fi', target: '_blank', icon: 'user', title: $i18n.t('login.link'), position: 'right' },
]));

const parsedError = computed(() => {
    if (process.env.NODE_ENV === 'development') {
        console.error(error);
    }

    return {
        statusCode: error.statusCode,
        title: error.title ?? String(error.statusCode),
        message: error.message,
        stack: error.stack,
        actions: {
            back: {
                buttonText: $i18n.t('backButtonText'),
            },
            homepage: {
                buttonText: $i18n.t('homepageButtonText'),
            },
            reportProblem: {
                buttonLink: $i18n.t('reportProblemButtonLink'),
                buttonText: $i18n.t('reportProblemButtonText'),
            },
        },
        usefulLinksTitle: $i18n.t('usefulLinksTitle'),
        usefulLinks: ['24h', 'feedback', 'some'].map(key => ({
            icon: 'call-24',
            href: $i18n.t(`${key}Href`),
            title: $i18n.t(`${key}Title`),
            subtitle: $i18n.t(`${key}Subtitle`),
            body: $i18n.t(`${key}Body`),
        })),
    };
});
useI18n({
    useScope: 'global',
    messages: {
        'fi-FI': {
            login: {
                link: 'Kirjaudu',
            },
            'usefulLinksTitle': 'Hyödylliset linkit',
            'backButtonText': 'Takaisin edelliselle sivulle',
            'homepageButtonText': 'Siirry etusivulle',
            'reportProblemButtonLink': 'tel:0800 131 031',
            'reportProblemButtonText': 'Ilmoita ongelmasta',
            '24hHref': 'tel:0800131031',
            '24hTitle': '24h-Asiakaspalvelu',
            '24hSubtitle': '0800 131 031',
            '24hBody': 'Soita Shell- ja St1-huoltamoihin liittyvissä asioissa maksuttomaan palvelunumeroomme.',
            'feedbackHref': 'tel:010030400',
            'feedbackTitle': 'Anna palautetta tai ota yhteyttä',
            'feedbackSubtitle': '0100 304 00',
            'feedbackBody': 'Kerro meille, missä olemme onnistuneet ja kuinka voisimme vielä kehittyä - näin onnistumme jatkossa entistä paremmin.',
            'someTitle': 'Ole yhteydessä somessa!',
            'someHref': 'https://www.facebook.com/st1suomi/',
            'someSubtitle': ' ',
            'someBody': 'Kerromme HelmiSimpukan viimeisimmät kuulumiset ja vastaamme yhteydenottoihin Facebookissa ja Instagramissa.',
            additionalLinks: {
                carwash: 'Autopesulat',
            },
        },
        'en-EU': {
            login: {
                link: 'Login',
            },
        },
    },
});
</script>
<style lang="scss">
html.error {
    body {
        background: var(--color-base-grey-100);

        .error {
            .ds-box.ds-box--shadow {
                box-shadow: none;
                background: var(--color-base-white);
            }

            h4 {
                text-align: center;
            }

            .useful-links {
                > .link {
                    background: var(--color-base-white);
                    margin-right: 0;
                    max-width: 100%;
                    padding: 1rem;
                    margin-bottom: 0;
                }

                .icon {
                    background: var(--color-primary-500);
                    color: var(--color-base-white);
                }
            }
        }
    }
}
</style>
